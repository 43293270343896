<template>
    <b-card>
        <form @submit.prevent="newItem" id="new-item">
            <b-form-group >
              <label for="title"> عنوان <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
                <b-form-input id="title" name="title" :disabled="disabled"></b-form-input>
            </b-form-group>
            <b-form-group label="خلاصه خبر" label-for="lead">
                <b-form-textarea id="lead" name="lead" data-required :disabled="disabled"></b-form-textarea>
            </b-form-group>
            <b-form-group >
              <label for="body"> متن خبر <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
                <b-form-textarea id="body" rows="5"  data-required :disabled="disabled"></b-form-textarea>
            </b-form-group>
            <div class="row">
              <div class="col-md-4">
                <b-form-group >
                  <label for="category_id"> دسته بندی <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
                  <b-form-select id="category_id" name="category_blog_id" :disabled="disabled">
                    <b-form-select-option :value="item.id" v-for="(item, index) in categories" :key="index">{{item.name}}</b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group >
                  <label for="image"> بارگذاری تصویر <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
                  <b-form-file id="image" name="image" :disabled="disabled"></b-form-file>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <label class="custom-switch" style="margin-top: 40px;">
                  <input type="checkbox" name="status" class="custom-switch-input" v-model="selected_status" :disabled="disabled">
                  <span class="custom-switch-indicator"></span>
                  <span class="custom-switch-description">وضعیت</span>
                </label>
              </div>
            </div>
           
            <button class="btn btn-success" type="submit" :disabled="disabled">ثبت خبر</button>
        </form>
    </b-card>
</template>
<script>
import mixins from '../mixins/mixins'

export default {
  mixins: [mixins],
  data () {
    return {
      url: '/api/admin/blogs',
      categories: [],
      title:'ثبت خبر',
      selected_status:'1'
    }
  },
  methods: {
    loadCategories () {
      this.$axios.get(this.$root.baseUrl + '/api/admin/blog-categories?all=true')
      .then(response => {
        this.categories = response.data.data
      })
    },
    newItem() {
        this.disabled = true
        const body = window.CKEDITOR.instances.body.getData()
        let form = document.getElementById('new-item')
        let formData = new FormData(form)
        formData.append('body',body)
        this.$axios.post(this.$root.baseUrl + this.url, formData)
          .then(response => {
            this.$root.success_notification('خبر با موفقیت ثبت شد')
            this.$router.push('/admin/blogs')
          })
          .catch(error => {
            this.$root.error_notification(error)
          })
          .finally(() => {
            this.disabled = false
          })
      },
  },
  mounted () {
    this.loadCkeditor()
  },
  created () {
    this.loadCategories()

    // window.blogCreated = (response) => {
    //   if (response.success) {
    //     this.success_notification('خبر با موفقیت ثبت شد')
    //     this.$router.push('/admin/blogs')
    //   }
    // }
  }
}
</script>
